<template>
  <div class="base-form__item">
    <div v-if="label" class="base-form__label-container">
      <label class="base-form__label">
        {{ label }}
      </label>
      <span v-if="tooltip" v-vuetippy="tooltip" class="base-form__tooltip-question">
        <question-svg></question-svg>
      </span>
      <ui-loader v-if="loading" class="wrapper-input"></ui-loader>
    </div>

    <div class="base-form_wrapper">
      <slot></slot>
      <input
        v-if="placeholder"
        v-model="value"
        class="base-form__input"
        :class="{'base-form__input-invalid': error, disabled}"
        :type="!type || (isTypePassword && isPasswordShown) ? 'text' : type"
        :placeholder="placeholder"
        :disabled="disabled"
        @focus="focus = true"
        @blur="focus = false"
      />
      <button
        v-if="isTypePassword"
        type="button"
        :title="`${isPasswordShown ? 'Скрыть' : 'Показать'} пароль`"
        @click="toggleIsPasswordShown()"
      >
        <component
          :is="isPasswordShown ? HidePass : ShowPass"
          class="base-form__eye hovered"
        ></component>
        <div class="base-form__ico hovered">
          <slot name="rightIcon"></slot>
        </div>
      </button>
    </div>
    <div v-if="error" class="base-form__text base-form__text-invalid">
      {{ error }}
    </div>
  </div>
</template>

<script setup lang="ts">
import 'sass/base/form.scss';

import {useToggle} from '@vueuse/core';
import {computed} from '#imports';
import UiLoader from '@/components/Loader.vue';
import HidePass from 'svg/cabinet/hidePass.vue';
import ShowPass from 'svg/cabinet/showPass.vue';
import QuestionSvg from 'svg/header/question.vue';

const props = defineProps({
  modelValue: {type: [String, Number], required: false},
  label: {type: String, required: false},
  type: {type: String, required: false},
  placeholder: {type: String, required: false},
  error: {type: String, required: false},
  focus: {type: Boolean, required: false},
  disabled: {type: Boolean, required: false},
  tooltip: {type: String, required: false},
  loading: {type: Boolean, required: false},
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | number | undefined): void;
  (e: 'update:focus', value: boolean): void;
}>();

const value = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emit('update:modelValue', val);
  },
});

const focus = computed({
  get() {
    return props.focus;
  },
  set(val) {
    emit('update:focus', val);
  },
});

const isTypePassword = computed(() => props.type === 'password');
const {0: isPasswordShown, 1: toggleIsPasswordShown} = useToggle(false);
</script>
